import { ActionIcon, Box, Divider, Paper, Text, Title, useMantineTheme, Grid, Group, Card, Stack } from '@mantine/core'
import { useState } from 'react'
import SimpleInstructions from '@/components/common/Simple Instructions'
import { IconAdjustCircle, IconEditModifyPen, IconHelpQuestion, IconTickCircle } from '@/components/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import { OnboardingSteps } from './OnboardingSteps'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'

const OnboardingOverview = () => {
  const [openHelp, setOpenHelp] = useState(false)
  const { stepCompletion } = useOnboardingForm()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const navigate = useNavigate()
  const { invitationCode } = useParams()
  const steps = OnboardingSteps()

  return (
    <>
      {openHelp && (
        <SimpleInstructions
          title='Application Process'
          description="Simply complete each step below and submit your application after reviewing it. You will be notified once it's processed."
          dismissHelp={() => setOpenHelp(false)}
        />
      )}
      <Paper p='lg' pos='relative' sx={{ zIndex: 1 }}>
        <Group grow>
          <Group>
            <ActionIcon variant='transparent' onClick={() => setOpenHelp(true)} color='secondary'>
              <IconHelpQuestion style={{ color: theme.colors.grey[4], height: '1.2em', width: '1.55em' }} />
            </ActionIcon>
          </Group>
          <Group justify='center'>
            <Title fz={{ base: 'xl', desktop: '2xl' }} order={2}>
              Welcome to Leikur!
            </Title>
          </Group>
          <Group></Group>
        </Group>

        <Divider color='dark' size='xs' mt='sm' />
        <Box ta='center' pt='md' pb='sm' c='secondary' maw='90ch' mx='auto'>
          <Text fw='bold' p='md'>
            Click through our intuitive UI to breeze through each step of your onboarding process effortlessly.
            <br />
            From verifying your identity to setting up accounts, our UI is designed for simplicity. Just follow the
            prompts and complete each stage at your own pace. Need help? Reach out anytime!
          </Text>
        </Box>
        <Grid>
          {steps.map((step) => {
            const isStepComplete = stepCompletion && stepCompletion[step.id]

            return (
              <Grid.Col span={isMobile ? 12 : 4} key={step.id}>
                <Card shadow='sm' padding={0} radius='xs' withBorder>
                  <Group
                    justify='space-between'
                    p='md'
                    mb='md'
                    bg={isStepComplete ? theme.colors.dark[0] : theme.colors.secondary[6]}
                  >
                    <Title c='light' order={5}>
                      {step.title}
                    </Title>
                    <ActionIcon
                      variant='transparent'
                      size='sm'
                      onClick={() => navigate(`/onboarding/${invitationCode}/${step.href}`)}
                    >
                      <IconEditModifyPen style={{ color: 'white' }} />
                    </ActionIcon>
                  </Group>
                  <Card.Section>
                    <Stack mb='lg' gap={0} mih='6rem'>
                      <Text size='sm' py='md' px='xl' fw='bold' ta='center'>
                        {step.description}
                      </Text>
                      <div style={{ flexGrow: 1 }} />
                      <Group justify='center' c={isStepComplete ? 'green' : 'red'}>
                        {isStepComplete ? (
                          <IconTickCircle style={{ height: '1.5em' }} />
                        ) : (
                          <IconAdjustCircle style={{ height: '1.5em' }} />
                        )}
                        <Text tt='uppercase'>{isStepComplete ? 'Completed' : 'Incomplete'}</Text>
                      </Group>
                    </Stack>
                  </Card.Section>
                </Card>
              </Grid.Col>
            )
          })}
        </Grid>
      </Paper>
    </>
  )
}

export default OnboardingOverview
