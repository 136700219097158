import { Anchor, Box, Breadcrumbs, Button, Checkbox, Flex, Group, Paper } from '@mantine/core'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IconArrowRight } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { checkboxStyles } from '../onboardingUtils'
import OnboardingStatus from '../status/OnboardingStatus'

export const DataProtectionValidationFields = ['hasAcceptedPrivacyPolicy']

const DataProtection = () => {
  const { invitationCode } = useParams()
  const { form, validateFields, saveForm } = useOnboardingForm()
  const navigate = useNavigate()

  const handleAccept = () => {
    if (validateFields(DataProtectionValidationFields)) {
      saveForm()

      navigate(`/onboarding/${invitationCode}/step/10`)
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 8 | Data Protection</Box>
        </Breadcrumbs>

        <Box py={{ base: 'lg', desktop: 'lg' }} lh='1.5' c='textPrimary'>
          Leikur respects your privacy and will process your personal data in accordance with the GDPR (General Data
          Protection Regulation) and other applicable legal acts.
          <br />
          <br />
          Please read our <Anchor>Privacy Policy</Anchor>.
          <Checkbox
            label='I accept the Privacy Policy'
            mt='md'
            styles={checkboxStyles}
            checked={form.getValues().hasAcceptedPrivacyPolicy}
            onChange={(event) => form.setFieldValue('hasAcceptedPrivacyPolicy', event.currentTarget.checked)}
          />
          <Group justify='space-between' mt='lg'>
            <Button onClick={() => navigate(`/onboarding/${invitationCode}/step/7`)} color='dark'>
              Previous
            </Button>
            <Button
              onClick={handleAccept}
              disabled={!form.getValues().hasAcceptedPrivacyPolicy} // Disable button until the checkbox is checked
            >
              Accept and continue
            </Button>
          </Group>
        </Box>
      </Paper>
    </Flex>
  )
}

export default DataProtection
