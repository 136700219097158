import { Box, Button, Flex, Group, useMantineTheme } from '@mantine/core'
import { IconLogout, IconMenu, IconMessage } from '../icons'
import { LogoLeikur } from '../logos'
import { useSignOut, useValidateSession } from '@/api/auth/hooks'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const AppHeader = ({ toggleNavbar }: { toggleNavbar: () => void }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { mutate: signOut, isSuccess: isSignOutSuccess } = useSignOut()
  const theme = useMantineTheme()
  const { data: isValidSession } = useValidateSession()

  useEffect(() => {
    if (isSignOutSuccess) {
      navigate('/sign-in')
    }
  }, [isSignOutSuccess])

  if (location.pathname === '/sign-in') {
    return null
  }

  return (
    <Flex
      h={64}
      p='0px 32px'
      justify={isValidSession ? 'space-between' : 'center'}
      align='center'
      bg={theme.colors.light[6]}
    >
      {isValidSession && (
        <Group>
          <Button
            leftSection={<IconMenu />}
            color='secondary'
            variant='subtle'
            size='medium'
            py={0}
            px='xs'
            onClick={toggleNavbar}
          >
            <Box c='dark' lh='18px'>
              Menu &amp;
              <br />
              Settings
            </Box>
          </Button>
        </Group>
      )}
      <Group justify='center'>
        <Box component={LogoLeikur} h={48} />
      </Group>
      <Group gap='2xs' display={{ base: 'none', desktop: 'flex' }}>
        {isValidSession && (
          <>
            <Button leftSection={<IconMessage />} color='secondary' variant='subtle' size='medium' py={0} px='xs'>
              <Box c='dark' lh='18px'>
                Message <br />+ Contact
              </Box>
            </Button>
            <Button
              leftSection={<IconLogout />}
              color='secondary'
              variant='subtle'
              size='medium'
              py={0}
              px='xs'
              onClick={() => signOut()}
            >
              <Box c='dark' lh='18px'>
                Log <br /> Out
              </Box>
            </Button>

            {/* <Button
          leftSection={<IconLogout />}
          color='secondary'
          variant='subtle'
          size='medium'
          py={0}
          px='xs'
          // onClick={() => {
          //   navigate('/sign-in')
          // }}
        >
          <Box c='dark' lh='18px'>
            Log <br /> In
          </Box>
        </Button> */}
          </>
        )}
      </Group>
    </Flex>

    // <Box pos='relative' h='5.5rem' sx={{ zIndex: 2 }}>
    //   <Paper pos='fixed' left={0} right={0} h='5.5rem' radius={0}>
    //     <Container display='flex' h='100%' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
    //       <Flex gap='sm' justify='flex-start' align='center' direction='row' w='100%'>
    //         {icon ? (
    //           <Flex justify='center' align='center' {...getIconStyles()} sx={{ borderRadius: '50%' }} pt='0.15rem' onClick={iconOnClick}>
    //             {icon}
    //           </Flex>
    //         ) : (
    //           <Flex {...getIconStyles()} p='0.65rem 0.2rem 0.65rem 0.75rem' sx={{ borderRadius: '50%' }} onClick={iconOnClick}>
    //             <IconPeople style={{ height: '100%' }} />
    //           </Flex>
    //         )}
    //         <Box>
    //           <Title order={2} c='dark' ff='Roboto' fw={600} fz='lg' lh={1.2} mt='0.2rem'>
    //             {title || ''}
    //           </Title>
    //           <Title order={2} c={colorAliases.textInvertSecondary} fw={400} fz='sm' lh={1.5} lineClamp={1}>
    //             {description}
    //           </Title>
    //         </Box>
    //       </Flex>
    //       <Flex gap='2xs' visibleFrom='desktop'>
    //         {buttons}
    //       </Flex>
    //     </Container>
    //   </Paper>
    // </Box>
  )
}

export default AppHeader
