import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { ActionIcon, Group, Paper, Stack, Text, useMantineTheme } from '@mantine/core'
import { OnboardingStepId } from '../overview/OnboardingSteps'
import { IconCloseCross, IconTickMarkYes, IconArrowLeft } from '../../../components/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'

const stepsMapping = [
  { id: 'step_1_basic_details', label: 'Basic Details', stepNumber: '1' },
  { id: 'step_2_contact_details', label: 'Contact Details', stepNumber: '2' },
  { id: 'step_3_advanced_details', label: 'Advanced Details', stepNumber: '3' },
  { id: 'step_4_representatives', label: 'Representatives', stepNumber: '4' },
  { id: 'step_5_financial_details', label: 'Financial Details', stepNumber: '5' },
  { id: 'step_6_tax_regulations', label: 'Tax Regulations', stepNumber: '6' },
  { id: 'step_7_business_activity', label: 'Business Activity', stepNumber: '7' },
  { id: 'step_8_data_protection', label: 'Data Protection', stepNumber: '8' },
  { id: 'step_9_documents', label: 'Documents', stepNumber: '9' },
  { id: 'step_10_review_application', label: 'Review Application', stepNumber: '10' },
]

const OnboardingStatus = () => {
  const { stepCompletion } = useOnboardingForm()
  const { invitationCode } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  if (!stepCompletion || isMobile) {
    return null
  }

  return (
    <Paper p='lg' style={{ flexShrink: 1, minWidth: '220px' }} bg='light.7'>
      <Stack>
        <Group
          wrap='nowrap'
          sx={() => ({
            cursor: 'pointer',
          })}
          onClick={() => navigate(`/onboarding/${invitationCode}/overview`)}
        >
          <ActionIcon size={40} color='#356C91'>
            <IconArrowLeft style={{ width: '30px', height: '30px' }} />
          </ActionIcon>
          <Stack gap={0}>
            <Text size='sm'>Dashboard</Text>
            <Text size='xs' c='grey'>
              Go to dashboard
            </Text>
          </Stack>
        </Group>
        {stepsMapping.map((step) => {
          const isActive = location.pathname.includes(`/onboarding/${invitationCode}/step/${step.stepNumber}`)
          return (
            <Group
              wrap='nowrap'
              key={step.id}
              sx={() => ({
                cursor: 'pointer',
              })}
              onClick={() => navigate(`/onboarding/${invitationCode}/step/${step.stepNumber}`)}
            >
              <ActionIcon
                size={40}
                color={isActive ? 'white' : stepCompletion[step.id as OnboardingStepId] ? '#5DC809' : '#F67D64'}
              >
                {stepCompletion[step.id as OnboardingStepId] ? (
                  <IconTickMarkYes style={{ width: '24px', color: isActive ? '#5DC809' : 'inherit' }} />
                ) : (
                  <IconCloseCross style={{ width: '24px', color: isActive ? '#F67D64' : 'inherit' }} />
                )}
              </ActionIcon>
              <Stack gap={0}>
                <Text size='sm' lineClamp={1}>
                  {step.label}
                </Text>
                <Text size='xs' c='grey.6'>
                  Step {step.stepNumber}
                </Text>
              </Stack>
            </Group>
          )
        })}
      </Stack>
    </Paper>
  )
}

export default OnboardingStatus
